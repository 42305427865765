.panel {
  height: 100%;
  flex-grow: 1;
  border-right: 1px solid rgba(218, 220, 224, 0.5);
  .notice {
    font-size: 12px;
    color: #666;
    text-align: center;
    padding: 18px; }
  .rooms-old {
    height: calc(100% - 55px - 41px - 45px - 25px); }
  .rooms {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-top: 0;
    background: #fff;
    .room {
       padding: 2vmin 0 2vmin;
       .title {
         color: #000; } } } }

.home-howitworks {
  background: #fff;
  padding: 0 0 10%; }

.home-howitworks-heroimage {
  padding: 0;
  text-align:center {}
  height: auto;
  max-width: 750px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: auto; }

.home-howitworks-hr {
  color: #335; }

.home-howitworks-copied {
  font-size: calc(3px + 2vmin);
  color: #00f;
  padding: 10px 10% 0;
  text-align: center; }

.home-howitworks-maintitle {
  font-size: 8vmin;
  text-align: center;
  color: #000;
  font-weight: bold;
  padding-top: 5vmin;
  padding: 5%; }

.home-howitworks-ul {
  padding-left: 10px; }

.home-howitworks-step1title {
  font-size: calc(20px + 2vmin);
  text-align: center;
  color: #000;
  font-weight: 400;
  padding: 5vmin 0 5vmin; }

.home-howitworks-example {
  font-size: calc(8px + 2vmin); }

.home-howitworks-3li {
  font-size: calc(8px + 2vmin);
  padding: 10px 0 10px 0; }

.home-howitworks-linktext {
  font-size: calc(10px + 2vmin);
  text-align: left;
  color: #00f;
  font-weight: normal;
  padding: 0;
  border: none;
  background: none; }

.home-howitworks-step2title {
  font-size: calc(8px + 2vmin);
  text-align: center;
  color: #000;
  font-weight: bold;
  padding: 0 5vmin 0;
  display: none; }

.home-howitworks-step2text {
  font-size: calc(8px + 2vmin);
  text-align: left;
  color: #000;
  font-weight: normal;
  padding: 0 10vmin 0; }

.home-howitworks-step3title {
  font-size: calc(8px + 2vmin);
  text-align: center;
  color: #000;
  font-weight: bold;
  padding: 0 5vmin 0; }

.home-howitworks-step3text {
  font-size: calc(8px + 2vmin);
  text-align: left;
  color: #000;
  font-weight: normal;
  padding: 0 10vmin 20vmin; }

.home-example-title {
  font-size: calc(12px + 3vmin);
  text-align: center; }

.home-example-image-wrapper {
  text-align: center;
  padding: 0 0 3vmin; }

.home-example-image {
  padding: 1vmin 0 1vmin;
  width: 80vmin; }

.earnings-topsection {
  height: auto;
  margin: auto;
  background-color: #fff;
  width: 100%;
  padding: 0 0 0 0;
  text-align: center; }

.welcome-topsection {
  height: auto;
  margin: auto;
  background-color: #fff;
  width: 100%;
  padding: 0 0 0 0;
  text-align: center; }

.welcome-topsection-title {
  font-size: calc(10px + 2vmin);
  color: #000;
  padding: 10% 0 0 0;
  margin: auto; }

.welcome-topsection-number {
  font-size: calc(20px + 2vmin);
  font-weight: 600;
  padding: 5% 2% 10%;
  color: #000;
  margin: 0 0 0 0; }

.earnings-title {
  font-size: calc(10px + 2vmin);
  color: #000;
  padding: 10% 0 0 0;
  margin: auto; }

.earnings-number {
  font-size: calc(20px + 2vmin);
  font-weight: 600;
  padding: 0 2% 2%;
  color: #000;
  margin: 0 0 20px 0; }

.earnings-callstitle {
  font-size: calc(10px + 2vmin);
  margin: 10% 0 0 0;
  padding: 5% 0 5%;
  color: #000;
  background-color: #fff;
  width: 100%;
  border: 1px solid #ccc; }

.earnings-balance {
  font-size: calc(10px + 2vmin);
  margin: 10% 0 0 0;
  padding: 5% 0 5%;
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%; }

.earnings-transactionlistheader {
  width: 100%;
  background-color: #aaf;
  color: #fff;
  border-top: 1px solid #335;
  padding: 5%;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin); }

.cashoutbutton {
  font-size: calc(10px + 2vmin); }

.welcome-topsection-header {
  width: 100%;
  background-color: #aaf;
  color: #fff;
  border-top: 1px solid #335;
  padding: 5vmin;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin); }

.home-howitworks-header {
  width: 100%;
  background-color: #aaf;
  color: #fff;
  border-top: 1px solid #335;
  padding: 5vmin;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin);
  margin: 10vmin 0 5vmin; }

.earnings-topsection-header {
  width: 100%;
  background-color: #aaf;
  color: #fff;
  border-top: 1px solid #335;
  padding: 5vmin;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin); }

.roomslist-header {
  width: 100%;
  background-color: #aaf;
  color: #fff;
  border-top: 1px solid #335;
  padding: 5vmin;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin); }

.profilepic .profilepic-settings {
    width: auto;
    height: 200px;
    max-height: 30%;
    border-radius: 100px;
    overflow: hidden;
    margin: auto;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 50px 3px #6666ff; }

.profilepic-old {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    margin: auto;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 0 13px #f0f0f0; }

.sharebutton {
  border: none;
  outline: 0;
  display: block;
  padding: 0.5vmin 0 0.5vmin;
  color: #FFF;
  background-color: #ce37f0;
  text-align: center;
  cursor: pointer;
  margin: auto;
  width: 80vmin;
  font-size: calc(14px + 2vmin);
  font-family: 'Open Sans';
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 3vmin; }

.copybutton {
  border: none;
  outline: 0;
  display: block;
  padding: 0.5vmin 0 0.5vmin;
  color: #FFF;
  background-color: #1e87f0;
  text-align: center;
  cursor: pointer;
  margin: auto;
  width: 80vmin;
  font-size: calc(14px + 2vmin);
  font-family: 'Open Sans';
  text-transform: uppercase;
  font-weight: bold; }

.copybutton2 {
  border: none;
  outline: 0;
  display: block;
  padding: 0.5vmin 0 0.5vmin;
  color: #FFF;
  background-color: #1e87f0;
  text-align: center;
  cursor: pointer;
  margin: auto;
  width: 80vmin;
  font-size: calc(14px + 2vmin);
  font-family: 'Open Sans';
  text-transform: uppercase;
  font-weight: bold;
  display: none; }


.profilelink {
  color: #fff;
  padding-top: 10%;
  font-size: 3vmin; }

.profilelinktextarea {
  color: #fff;
  background: transparent; }

.postcall-thankyou {
    background: #666;
    padding: 10vmin 0 0;
    color: #fff;
    text-align: center; }

.postcall-wrapper-thankyou {
    background: #666;
    border: none;
    padding: 5vmin 0 0;
    margin: 0; }

.postcall-logowrapper {
    background: #666;
    padding: 0 0 3vmin;
    text-align: center; }

.postcall-logo {
    width: 66vmin; }

.postcall-topsection-header {
    color: #666;
    background-color: #ccc;
    padding: 3vmin 5vmin 3vmin;
    font-weight: 600;
    font-size: calc(8px + 2vmin);
    text-align: center; }

.postcall-timelabel {
    font-size: calc(8px + 2vmin);
    text-align: center;
    padding-top: 3vmin; }

.postcall-time {
    font-size: calc(10px + 2vmin);
    font-weight: 600;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: -2vmin;
    color: #66f; }

.postcall-earningslabel {
    font-size: calc(8px + 2vmin);
    text-align: center;
    padding-top: 3vmin; }

.postcall-earnings {
    font-size: calc(30px + 2vmin);
    font-weight: 600;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: -2vmin;
    color: #0b0; }

.postcall-ratingwrapper {
    padding: 5vmin 5vmin 5vmin; }

.postcall-ratinglabel {
    font-size: calc(6px + 2vmin);
    text-align: center; }

.postcall-ratingbox {
    text-align: center; }

@media screen and (min-width: 1200px) {
  .panel {
    min-width: 360px; } }

@media screen and (min-width: 700px) and (max-width: 1199px) {
  .panel {
    min-width: 300px; } }

@media screen and (max-width: 699px) {
  .panel {
    width: 100%; } }

.theme.light .panel {
  background: #fff; }

.theme.dark .panel {
  background: #303841; }

.active {
  color: #da7d02 !important; }
