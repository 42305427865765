
.sidemenu {
  background: #30a;
  color: #fff;
  .link {
    color: #fff; } }

.menuname {
  color :#aaf !important {} }

@media screen and (min-width: 700px) {
  .mobile {
    display: none; } }
