.login {
  width: 100vmin;
  background-image: url('/background/640x700.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%; }

.login-scrollable {
  overflow-y: auto;
  height: 100%;
  width: 100%; }

.loginLogoContainer {
  width: 33vmin;
  position: relative;
  top: -10vmin; }

.loginContainer {
  width: 80vmin; }

.login-inner {
  min-height: 420px;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%; }

.toggle-credits {
  width: 100%; }

.introWrapper {
  height: auto;
  width: 100%;
  background: #fff; }

.startButtonWrapper {
  padding: 0; }

.loginButtonWrapper {
  padding: 0; }

.elevatorpitch-image {
  padding: 0;
  text-align:center {}
  height: auto;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  margin: auto;
  overflow: auto;
  display: none; }

.elevatorpitch-wrapper {
  background-image: linear-gradient(185deg,transparent 38%,rgba(0,0,0,.75) 60%,rgba(0,0,0,.5) 95%),url(/IntroBackground6h.jpg);
  background-size: cover;
  height: 85vmin;
  width: 100vw;
  min-height: 520px;
  position: relative;
  background-position: 75% center; }

.elevatorpitch-overlay {
  position: relative;
  height: 100%;
  top: 48vmin; }

.elevatorpitch {
  color: #fff;
  font-size: calc(min(6px + 5vmin,40px));
  padding: 0vmin 5vmin 1vmin;
  font-weight: 800;
  font-family: 'Open Sans';
  position: relative;
  line-height: 1.4;
  margin-top: -2vmin;
  text-shadow: 1px 1px 0px #000000, 0 0 0px #fff; }

.elevatorpitch-sub {
  color: #fff;
  font-size: calc(8px + 2vmin);
  padding: 1vmin 5vmin 3vmin;
  font-family: 'Open Sans';
  font-weight: 200;
  line-height: 1.4; }

.introBenefits {
  background: #66f;
  text-align: center;
  max-width: none; }

.introbenefit-title {
  color: #fff;
  font-size: 7vmin;
  padding: 20px 20px 0;
  font-weight: 800;
  font-family: 'Open Sans'; }

.introbenefit-headerimage {
  padding: 0;
  text-align:center {}
  max-width: 300vmin;
  height: 15vmin;
  margin: auto;
  overflow: auto;
  margin-top: 50px; }

.introbenefit-header {
  color: #fff;
  font-size: calc(14px + 2vmin);
  padding: 20px 20px 0;
  font-weight: 800;
  font-family: 'Open Sans'; }

.introbenefit-sub {
  color: #fff;
  font-size: calc(12px + 2vmin);
  padding: 10px 20px 40px;
  font-family: 'Open Sans';
  font-weight: 200; }

.introfooter1 {
  color: #fff;
  font-size: calc(10px + 2vmin);
  padding: 20px 20px 0;
  font-family: 'Open Sans';
  font-weight: 200;
  border-top: 1px solid #aaa; }

.introfooter2 {
  color: #fff;
  font-size: calc(6px + 2vmin);
  padding: 0px 20px 20px;
  font-family: 'Open Sans';
  font-weight: 200;
  margin: 0px; }

.MuiStepper-root {
  display: none !important; }

.registerContainer {
  text-align: center;
  background: #eef;
  background: #66f;
  min-height: 93%;
  padding: 3%;
  padding: 0; }

.registerform-title {
  color: #fff;
  font-size: calc(8px + 2vmin);
  padding: 20px 20px 0;
  font-weight: normal;
  font-family: 'Open Sans'; }

.registerform-title1 {
  color: #000;
  font-size: calc(6px + 2vmin);
  padding: 0px 20px 0;
  font-weight: normal;
  font-family: 'Open Sans';
  padding: 0; }

.registerform-title2 {
  color: #000;
  font-size: calc(6px + 2vmin);
  padding: 0px 20px 20px;
  font-weight: normal;
  font-family: 'Open Sans'; }

.registerform-title3 {
  color: #000;
  font-size: calc(6px + 2vmin);
  padding: 0;
  font-weight: normal;
  font-family: 'Open Sans'; }

.registerform-terms {
  color: #fff;
  font-size: calc(6px + 2vmin);
  padding: 0;
  font-weight: normal;
  font-family: 'Open Sans';
  text-align: center; }

.registerform-copyright {
  color: #eee;
  font-size: calc(4px + 2vmin);
  padding: 15% 0;
  font-weight: normal;
  font-family: 'Open Sans'; }

.loginform-agree {
  color: #fff;
  font-size: 4vmin;
  padding: 20px 20px 0;
  font-weight: normal;
  font-family: 'Open Sans'; }

.loginform-copyright {
  color: #fff;
  font-size: 3vmin;
  padding: 10px 20px 20px;
  font-weight: normal;
  font-family: 'Open Sans'; }

.registerbutton {
  border-radius: 0;
  background-color: #6fa !important;
  color: #000;
  text-transform: uppercase;
  font-weight: 600 !important;
  width: 100%;
  height: 10%; }

.loginbutton {
  border-radius: 0;
  background-color: #6fa !important;
  color: #000;
  text-transform: uppercase;
  font-weight: 600 !important;
  width: 100%;
  height: 10%; }

.registerbutton1 {
  background-color: #6fa;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  height: 10%;
  margin-top: 2vmin; }

.startbutton {
  border: none;
  outline: 0;
  display: block;
  padding: 1vmin 0 1vmin;
  color: #000;
  background-color: #66FFAA;
  text-align: center;
  cursor: pointer;
  margin: auto;
  width: 90vmin;
  height: 10%;
  font-size: calc(12px + 2vmin);
  font-family: 'Open Sans';
  text-transform: uppercase;
  font-weight: 800;
  box-shadow: 0 0px 60px rgba(255,255,255,0.66); }

.loginButton {
  border: none;
  outline: 0;
  display: block;
  padding: 10px 20px 10px;
  color: #eee;
  background: transparent;
  text-align: center;
  cursor: pointer;
  margin: auto;
  width: 80%;
  height: 2%;
  font-size: 4vmin;
  font-weight: 400; }


@media screen and (max-height: 600px) {
  .login-scrollable {
    align-items: flex-start; } }

@media screen and (max-height: 620px) {
  #copyright {
    display: none; } }

@media screen and (min-width: 500px) {
  .elevatorpitch-overlay {
    top: 44vmin; } }

@media screen and (min-width: 640px) {
  .login {
    background-image: url('/background/960x700.jpg'); }
  .elevatorpitch-overlay {
    top: 42vmin; }
  .elevatorpitch {
    padding-top: 10vmin; } }

@media screen and (min-width: 960px) {
  .login {
    background-image: url('/background/1200x900.jpg'); }
  .elevatorpitch-overlay {
    top: 40vmin; }
  .elevatorpitch {
    padding-top: 9vmin; } }

@media screen and (min-width: 1200px) {
  .login {
    background-image: url('/background/1600x950.jpg'); }
  .elevatorpitch-overlay {
    top: 38vmin; }
  .elevatorpitch {
    padding-top: 12vmin; } }

@media screen and (min-width: 1600px) {
  .login {
    background-image: url('/background/2000x1050.jpg'); }
  .elevatorpitch-overlay {
    top: 36vmin; }
  .elevatorpitch {
    padding-top: 15vmin; } }

.logo {
  padding: 0 95px 10px 95px; }
