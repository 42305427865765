.app {
  width: 100%;
  height: 100%;
  display: flex; }

.content {
  flex-grow: 1;
  overflow: hidden; }

.app-gray {
    background-color: #f0f0f0; }

.card {
  margin: auto;
  text-align: center;
  background-color: #fff;
  max-width: 300px;
  max-height: 100%;
  width: 100%;
  margin: 25% auto auto;
  padding: 0 20px 20px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.075); }

.title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #333; }

.subtitle {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #555; }


.profilebutton {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 10px;
  color: white;
  background-color: #007bff;
  text-align: center;
  cursor: pointer;
  width: 75%;
  height: 10%;
  font-size: 18px; }

.button-darkblue {
    background-color: #003b99; }

.a {
  text-decoration: none;
  font-size: 22px;
  color: black; }

.button:hover {
  opacity: 0.7; }


.main {
  flex-grow: 1;
  background: #f2f7f7;
  .content {
    flex-grow: 1;
    .profile {
      overflow: hidden;
      width: 150px;
      height: 150px;
      border-radius: 75px;
      .img {
        display: flex;
        width: 150px;
        height: 150px;
        border-radius: 75px;
        justify-content: center;
        align-items: center;
        background: #666;
        color: #f7f7f8;
        font-size: 72px; } }
    .welcome, .tutorial {
      height: 75px;
      padding: 18px;
      text-align: center; }
    .welcome {
      font-size: 30px;
      font-weight: bold; }
    .tutorial {
      font-size: 14px; } } }

@keyframes navigate {
  from {
    left: 100%; }
  to {
    left: 0; } }

@keyframes exit {
  from {
    left: 0; }
  to {
    left: 100%; } }

@media screen and (max-width: 5700px) {
  .main {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10; }
  .main.over {
    display: flex;
    animation-name: navigate;
    animation-duration: 0.2s;
    animation-timing-function: linear; }
  .main.exit {
    display: flex;
    left: 100%;
    animation-name: exit;
    animation-duration: 0.2s;
    animation-timing-function: linear; }
  .profilepic {
    width: 200px;
    height: 200px; } }
