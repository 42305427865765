.convo-bar {
  width: 100%;
  height: auto;
  font-size: 4vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 54px;
  position: relative;
  border-bottom: 1px solid rgba(218, 220, 224, 0.5);
  background: rgba(60, 60, 60, .05);
  .name {
    font-size: 4vw; }
  .icon {
    font-size: 18px;
    padding: 0 2px 5px 13px;
    color: #666;
    cursor: pointer; }
  .icon:hover {
    color: #000; }
  .search {
    flex-grow: 1;
    input {
      background: transparent;
      border: 0;
      border-radius: 0; } } }
