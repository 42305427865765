.body {
    overflow-y: visible;
    color: #fff; }

.html {
    overflow-y: visible; }

.theme {
  overflow-y: scroll; }

.LoginScreen {
    color: #fff;
    font-size: 4vw;
    width: 66%;
    margin: 154px auto 100px auto;
    .uk-input {
      padding: 7%;
      border-radius: 0;
      font-size: 3vw; }
    .uk-button {
      font-size: 3vw;
      padding: 3%;
      background-color: #0fa;
      color: #000;
      border-radius: 0; }
    .uk-text-small {
      font-size: 3vw; } }

.socialButtonList {
  padding-top: 10vh; }

.socialButtonPreloginText {
  font-size: calc(5px + 2vw);
  color: #000; }

.socialButton {
  width: 75%;
  color: #000;
  cursor: pointer;
  display: inline-block;
  padding: 2vw;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  -moz-border-radius: .3em;
  -webkit-border-radius: .3em;
  border-radius: .3em;
  font-size: 4vw; }

.btn-facebook {
  background-color: #3B5998;
  color: white; }

.btn-google {
  background-color: #fff;
  color: black; }

.google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff; }

.google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px; }

.googlepaybutton {
        width: 70vw;
        height: 5vh; }

.prepaycheckmark {
  font-weight: 800;
  color: #0fa;
  margin-right: 2vw; }

.prepaytext {
  font-size: 4vw; }

.paymentSetupScreen {
    color: #fff;
    font-size: 4vw;
    width: 75%;
    margin: 100px auto 100px auto;
    padding: 100px 0 100px 0; }

.paymentSetupTitle {
    color: #fff;
    font-size: 6vw; }

.paymentSetupSubtitle {
    color: #fff;
    font-size: 3vw;
    margin-bottom: 30px; }

.paymentScreen {
    color: #fff;
    font-size: 4vw;
    width: 66%;
    margin: 154px auto 100px auto;
    padding: 100px 0 100px 0; }

.GoogleLoginButton {
  color: #000 !important;
  padding: 5px 9vw 5px !important;
  font-size: calc(8px + 2vw) !important;
  font-weight: 800 !important;
  box-shadow: 0 0 40px rgba(255,255,255,0.66) !important; }

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
  border-radius: 4px; }

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc; }

.StripePaymentCardField {
  width: 80%; }

.StripePaymentCardButton {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 4%;
  color: #000;
  background-color: #0fa;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 10%;
  font-size: 5vw;
  font-weight: 600;
  border-radius: 0px;
  margin-top: 20px; }

.StripeElement {
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  color: #9df;
  background-color: #333;
  border: 1px solid transparent;
  border-radius: 1px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }


.StripeElement--invalid {
  border-color: #fa755a; }


.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.landingpageemailform {
  width: 75%;
  margin: auto;
  padding-top: 10%;
  .uk-border-pill {
    border-radius: 0px; } }

.enteremailtext {
  font-size: 3vw;
  color: #fff; }

.preLoginText {
  margin: 5vh 1vw 2vh;
  font-size: calc(5px + 2vw);
  color: #fff; }

.visitorBenefits {
  background: #fff;
  text-align: center; }

.visitorbenefit-title {
  color: #000;
  font-size: calc(20px + 2vw);
  padding: 0;
  font-weight: 800;
  font-family: 'Open Sans'; }

.visitorbenefit-headerimage {
  padding: 0;
  text-align:center {}
  max-width: 300vw;
  height: 15vh;
  margin: auto;
  overflow: auto;
  margin-top: 50px; }

.visitorbenefit-header {
  color: #000;
  font-size: calc(14px + 2vw);
  padding: 20px 20px 0;
  font-weight: 800;
  font-family: 'Open Sans'; }

.visitorbenefit-sub {
  color: #000;
  font-size: calc(8px + 2vw);
  padding: 10px 20px 40px;
  font-family: 'Open Sans';
  font-weight: 200; }

.visitorfooter1 {
  color: #000;
  font-size: calc(4px + 2vw);
  padding: 20px 20px 0;
  font-family: 'Open Sans';
  font-weight: 200;
  border-top: 1px solid #aaa; }

.visitorfooter2 {
  color: #000;
  font-size: calc(2px + 2vw);
  padding: 0px 20px 20px;
  font-family: 'Open Sans';
  font-weight: 200;
  margin: 0px; }

.panel {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  flex-grow: 1;
  border-right: 1px solid rgba(218, 220, 224, 0.5);
  background-color: #55efc4 !important;
  background-image: linear-gradient(120deg,#100030,#000 66%) !important;

  .notice {
    font-size: 12px;
    color: #666;
    text-align: center;
    padding: 18px;
    .rooms-old {
      height: calc(100% - 55px - 41px - 45px - 25px); }
    .rooms {}
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; } }

.profilescreen {
  overflow-y: visible; }

.profileview {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  background: #fff; }

.profilecard-old {
  height: calc(100% - 55px -41px -45px -25px -110px); }

.profilecard {
  height: auto;
  margin: auto;
  text-align: center;
  background: linear-gradient(180deg, rgba(102,102,255,1) 0%, rgba(153,153,255,1) 40%, rgba(46,0,255,1) 125%);
  width: 100%;
  margin: 0 auto auto;
  padding: 2vh 0 calc(5vw - 30px);
  border-radius: 0px; }

.profilecard-content {
  max-width: 800px;
  margin: auto;
  padding: 1vw 0;
  border-radius: calc(5vw - 35px); }

.availableText {
  font-size: calc(5px + 2vw); }

.profiletitle {
  font-size: calc(16px + 2vw);
  font-weight: 600;
  margin-bottom: 0px;
  color: #fff; }

.profilesubtitle {
  font-size: calc(min(8px + 2vw, 32px));
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #fff; }

.profileprice {
  font-size: calc(16px + 2vw);
  margin-bottom: 0;
  margin-top: 10%;
  color: #000; }

.profileminutes {
  font-size: 4vw;
  margin-top: 0;
  margin-bottom: 10px;
  color: #000; }

.profilepic {
    width: auto;
    height: 200px;
    max-height: 30vh;
    border-radius: 100px;
    overflow: hidden;
    margin: auto;
    transition: all 0.3s ease-in-out;
    border: 1vh solid #ccc; }

.profilepage-defaultpic {
    overflow: hidden;
    margin: auto;
    transition: all 0.3s ease-in-out;
    padding: 3vh 0 0; }

.profilebutton {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 4%;
  color: #000;
  background-color: #0fa;
  text-align: center;
  cursor: pointer;
  width: 75%;
  height: 10%;
  font-size: 5vw;
  font-weight: 600; }

.profilebutton2 {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 4%;
  color: #000;
  background-color: #0fa;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 10%;
  font-size: 5vw;
  font-weight: 600;
  border-radius: 0px; }

.button-darkblue {
    background-color: #003b99; }

.h3 {
    color: #fff !important; }

.w3-third {
    color: #fff !important; }

.availableWrapper {
  padding-top: 5vh;
  font-size: 4vw;
  color: #fff; }

.availableGreenLight {
  height: 3vw; }

.benefitcontainer {
    padding: 0 0 0 0;
    box-shadow: 0px 0px 12px 4px #66f; }

.benefitcard {
    background-color: #103;
    text-align: center;
    padding: 20px 20px 20px 20px;
    box-shadow: 0 0 10px 1px #30a;
    background-image: linear-gradient(145deg,#30a,#66f 100%) !important; }

.benefit {
    font-size: 4vw;
    text-align: center;
    padding: 15px;
    color: #fff; }

.cardtitle {
    color: #fff;
    font-size: 4vw;
    text-align: center;
    padding-top: 15px; }

.cardtext {
    color: #fff;
    font-size: 14px; }


@media screen and (min-width: 1200px) {
  .profilecard-content-old {
    background-color: rgba(0,0,0,0.33); } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .profilecard-content-old {
    background-color: rgba(0,0,0,0.2); } }

@media screen and (max-width: 999px) {
  .panel {
    width: 100%; } }

@media screen and (max-width: 699px) {
  .profilepic {
    height: 150px; } }

@media screen and (min-height:999px) {
  .profilecard-old {
     padding-top: 30%; } }

.theme.light .panel {
  background: #fff; }

.theme.dark .panel {
  background: #303841; }

.active {
  color: #da7d02 !important; }
