$nav-background: #666;
$panel-background: #666;

.profilepic-settings {
    width: auto;
    height: 200px;
    max-height: 30%;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    margin: auto;
    top: -60px;
    transition: all 0.3s ease-in-out; }

.settings-profileview {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 66px;
  width: 100%;
  height: 100%; }

.settings-editprofilebutton {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 2px;
  color: #fff;
  background-color: #1e87f0;
  text-align: center;
  cursor: pointer;
  width: 66vmin;
  font-size: calc(12px + 2vmin);
  font-weight: bold; }

.settings-profileviewheader {
  width: 100%;
  background-color: #aaf;
  color: #fff;
  border-top: 1px solid #335;
  padding: 5vmin;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin); }

.settings-profilecard {
  height: auto;
  margin: auto;
  text-align: center;
  background: #fff;
  width: 100%;
  margin: 0 auto auto;
  padding: 20px 20px 20px;
  border-radius: 6px; }

.settings-profiletitle {
  font-size: calc(20px + 2vmin);
  font-weight: 600;
  margin-bottom: 0px;
  color: #000; }

.settings-profilesubtitle {
  font-size: calc(12px + 2vmin);
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #000; }

.settings-profileprice {
  font-size: calc(20px + 2vmin);
  margin-bottom: 0;
  margin-top: 10%;
  color: #000; }

.settings-profileminutes {
  font-size: calc(12px + 2vmin);
  margin-top: 0;
  margin-bottom: 10px;
  color: #000; }

.settings-profilepic {
    width: auto;
    height: 200px;
    max-height: 30%;
    border-radius: 100px;
    overflow: hidden;
    margin: auto;
    transition: all 0.3s ease-in-out;
    border: 1vmin solid #ccc; }


.settings-generalcard {
  height: auto;
  margin: auto;
  text-align: center;
  background: #fff;
  width: 100%;
  margin: 0 auto auto;
  padding: 5vmin 10vmin 10vmin;
  border-radius: 6px;
  color: #000;
  font-size: calc(10px + 2vmin); }

.toggle-control {
  display: block;
  position: relative;
  padding-left: 100px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none {
     -moz-user-select: none {
      -ms-user-select: none {
          user-select: none; } } } }

.toggle-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.toggle-control input:checked ~ .control {
  background-color: dodgerblue; }

.toggle-control input:checked ~ .control:after {
  left: 55px; }

.toggle-control .control {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100px;
  border-radius: 25px;
  background-color: darkgray;
  -webkit-transition: background-color 0.15s ease-in;
  transition: background-color 0.15s ease-in; }

.toggle-control .control:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background: white;
  -webkit-transition: left 0.15s ease-in;
  transition: left 0.15s ease-in; }

.settings-generalheader {
  width: 100%;
  background-color: #aaf;
  color: #fff;
  border-top: 1px solid #335;
  padding: 2vmin 0 2vmin;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: calc(12px + 2vmin); }

.settings {
  padding-top: 40%;

  .picture {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    // margin: 24px 0 10px 0
    position: relative;
    width: 150px;
    cursor: pointer;

    .img {
      display: flex;
      width: 150px;
      height: 150px;
      border-radius: 75px;
      justify-content: center;
      align-items: center;
      background: $nav-background;
      color: #f7f7f8;
      font-size: 50px; }

    img {
      width: 150px;
      height: 150px;
      border-radius: 75px; }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: #000000;
      border-radius: 150px; } }

  .picture:hover .overlay {
    opacity: 0.7;

    .text {
      color: #ffffff;
      font-size: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center; } } }
