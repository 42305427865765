html, body, #root, .theme {
  background: #303841;
  position: fixed;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans';
  font-size: calc(6px + 2vmin); }


body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: 'Open Sans', source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.swiper-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 0;
  background: #FFF;
  padding-bottom: 50px; }

.swiper-slide {
  width: 100%;
  height: 100%; }

.swiper-pagination-bullet {
  opacity: 1;
  border: #6af solid 1px;
  background-color: transparent !important; }
.swiper-pagination-bullet-active {
  background-color: #6af !important; }

.topbarlogo {
    padding-left: 5px;
    width: 15vmin;
    height: auto;
    min-width: 96px;
    max-width: 80%; }

.startcallbutton {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 4px 20px 4px;
  background-color: #6fa;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  color: #000 !important;
  text-transform: uppercase; }

.top-bar {
  width: 100%;
  min-height: 7vmin;
  border-bottom: 0px solid rgba(218, 220, 224, 0.5);
  background-color:#6666ff !important {}
  overflow: visible;
  position: sticky;
  top: 0;
  color: #fff;
  z-index: 1000;
  padding: 5vmin;
  .dot {
    margin-left: -26px;
    margin-right: 11px;
    margin-top: 28px;
    width: 11px;
    height: 11px;
    border-radius: 8px;
    background: gray;
    border: 2px solid #fff; }
  .online {
    background: #55d48b; }
  .away {
    background: orange; }
  .busy {
    background: #d61314; }
  .offline {
    color: gray; }
  .profile {
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 7px 12px;
    cursor: pointer;
    .img {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      background: #666;
      color: #f7f7f8;
      font-size: 18px; } }
  .chatname {
      font-size: 4vmin;
      margin: auto;
      padding-left: 5px; }
  .nav {
    display: flex;
    padding-right: 10px;
    .button {
      color: #fff !important;
      font-size: calc(16px + 2vmin);
      padding: 0px;
      cursor: pointer;
      margin-top: -8px; }
    .button:hover {
      color: #fbb; }
    .backbutton {
      padding-left: 4px; } } }



// Custom variables and overwrites.
$global-link-color: #DA7D02;

// UIkit defaults.
@import "../node_modules/uikit/src/scss/variables-theme.scss";
@import "../node_modules/uikit/src/scss/mixins-theme.scss";

// Mixin overwrites.
@mixin hook-card() {
  color: #000; }

// Import UIkit.
@import "../node_modules/uikit/src/scss/uikit-theme.scss";

.uk-dropdown {
  border: 1px solid rgba(218, 220, 224, 0.5);
  box-shadow: none; // -0 0 3px rgba(0, 0, 0, 0.05)
  padding: 2px;
  min-width: 150px;
  font-size: 12px;
  .link {
    padding: 8px 12px;
    color: #666;
    cursor: pointer;
    display: flex;
    .icon {
      padding-left: 4px;
      margin-top: -2px; } }
  .link:hover {
    background: rgba(218, 220, 224, 0.2); }
  .divider {
    width: 100%;
    height: 0;
    border-bottom: 1px solid rgba(218, 220, 224, 0.5); } }

.uk-button.uk-button-honey {
  background: #da7d02;
  color: #ffffff; }
